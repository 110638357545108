import React, {useEffect, useRef, useState} from 'react';
import styles from "./Main.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronLeft, faChevronRight, faXmark} from "@fortawesome/free-solid-svg-icons";
import {useScrollbar} from "../../hooks";
import {Link} from "react-router-dom";
import {getNextWorkDay, getUserWorkMonth, setWorkState} from "../../http/workAPI";
import {getBirthday, gotSick} from "../../http/userAPI";
import {getDayInMonth, getMonth, getWeekDay} from "../../utils/dateWorker";



const Main = ({user, setUser}) => {
    const [monthlySchedule, setMonthlySchedule] = useState([{day: 0, dayOfWeek: "", state: 0}]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [birthdayUser, setBirthdayUser] = useState({name: "", role: "", month: 0, day: 0})
    const [nextWorkingShift, setNextWorkingShift] = useState({date: "", dayOfWeek: ""})

    const daysWrapper = useRef(null);
    useScrollbar(daysWrapper, monthlySchedule.length >= 28);

    const getUserBirthday = async () => {
        const data = await getBirthday();
        if (data.user) setBirthdayUser({name: data.user.firstName, role: data.user.role.name, month: new Date(data.user.dateOfBirth).getMonth()+1, day: new Date(data.user.dateOfBirth).getDate()})
    }
    const getUserState = async () => {
        const data = await getUserWorkMonth(currentDate.getMonth(), currentDate.getFullYear());

        const monthlyState = [];
        for (let i = 1; i <= getDayInMonth(currentDate.getFullYear(), currentDate.getMonth()+1); i++) {
            console.log(data);
            const searchDay = data.filter(el => new Date(el.date).getDate() === i);
            console.log(searchDay);
            if (searchDay.length) {
                monthlyState[i - 1] = {day: i, dayOfWeek: getWeekDay(i, currentDate.getFullYear(), currentDate.getMonth()), state: searchDay[0].stateId};
            } else
                monthlyState[i - 1] = {day: i, dayOfWeek: getWeekDay(i, currentDate.getFullYear(), currentDate.getMonth()), state: 1};
        }
        console.log(monthlyState)
        setMonthlySchedule([...monthlyState]);
    }
    const changeMonth = (change) => {
        if (currentDate.getMonth() + 1 + change === (new Date().getMonth()))
            return setCurrentDate(new Date());

        const date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + change,
            1
        );
        setCurrentDate(date);
    }

    useEffect(() => {
        getUserBirthday();
        getNextWorkDay()
            .then(res=>{
                if (Object.keys(res).length){
                    let date = new Date();
                    date.setTime(res.date);
                    setNextWorkingShift({
                        date: `${date.getDate()}.${date.getMonth()+1}`,
                        dayOfWeek: getWeekDay(date.getDate(), date.getFullYear(), date.getMonth(), true)
                    })
                }
            })
            .catch(err=>console.error(err.message))

    }, []);
    useEffect(() => {
        getUserState();
    }, [currentDate]);

    const logOut = () => {
        localStorage.removeItem("token");
        setUser(null);
    }

    const setDayState = async (event, day=0) => {
        let date;
        let state = 3;
        if (day === 0) {
            // date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
            date = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
            const dayState = monthlySchedule.filter(el=>el.day===currentDate.getDate())[0];
            dayState.state = 3;
            await gotSick();
            alert("Сообщение отправлено");
            setMonthlySchedule([...monthlySchedule.slice(0, currentDate.getDate()-1), dayState, ...monthlySchedule.slice(currentDate.getDate())]);
        } else {
            // date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${day}`;
            date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day)
            const dayState = monthlySchedule.filter(el=>el.day===day)[0];
            state = dayState.state === 1 || dayState.state === 3 ? 2 : 1
            dayState.state = state;
            setMonthlySchedule([...monthlySchedule.slice(0, day-1), dayState, ...monthlySchedule.slice(day)]);
        }

        const data = await setWorkState(state, date.getTime());
    }

    return (
        <main>
            <div className={styles.main__headerBlock}>
                <div className={styles.main__headerBlockAvatar}>
                    <div></div>
                </div>
                <div className={styles.main__headerBlockInfo}>
                    <span>Привет, {user.firstName}!</span>
                    <div>
                        <a onClick={logOut}>Выйти</a>
                        <Link to={"/admin"}>Админ-панель</Link>
                    </div>
                </div>
            </div>
            <div className={styles.main__incomeBlock}>
                <div className={styles.main__incomeBlockInfo}>
                    <span>17,345₽<span> +910₽</span></span>
                    <span>На этой неделе</span>
                </div>
                <div className={styles.main__incomeBlockButtons}>
                    <button>Подробнее</button>
                    <a>Статистика</a>
                </div>
            </div>
            <div className={styles.main__workShiftBlock}>
                <span>СЛЕДУЮЩАЯ СМЕНА:</span>
                <span>{nextWorkingShift.date ? `${nextWorkingShift.dayOfWeek}, ${nextWorkingShift.date}`: `Нет данных`}</span>
                <Link to={"/chart"}>Кто завтра работает?</Link>
            </div>
            <div className={styles.main__calendarBlock}>
                <div className={styles.main__calendarBlockMonth}>
                    <FontAwesomeIcon icon={faChevronLeft} onClick={() => changeMonth(-1)}/>
                    <span>{getMonth(currentDate.getMonth())} {currentDate.getFullYear()}</span>
                    <FontAwesomeIcon icon={faChevronRight} onClick={() => changeMonth(1)}/>
                </div>
                <div className={styles.main__calendarBlockDays}
                     ref={daysWrapper}
                >
                    <div
                        className={styles.main__calendarBlockDaysCarousel}
                    >
                        {
                            monthlySchedule
                                ?
                                monthlySchedule.map(el =>
                                    <div key={el.day} className={styles.main__calendarBlockDaysCarouselDay}>
                                        <span>{el.dayOfWeek}</span>
                                        <span>{el.day}</span>
                                        <div
                                            onClick={(event) => setDayState(event, el.day)}
                                            style={el.state === 3 ? {background: "#DF1010"} : {}}>{el.state === 1 ? "" : el.state === 2 ? (
                                            <FontAwesomeIcon icon={faCheck}/>) : (
                                            <FontAwesomeIcon icon={faXmark}/>)}</div>
                                    </div>
                                )
                                :
                                <></>
                        }

                    </div>
                </div>
            </div>
            <div className={styles.main__birthdayBlock}>
                <div className={styles.main__birthdayBlockInfo}>
                    <span>День рождения</span>
                    <span>{birthdayUser.name ? `${birthdayUser.role} ${birthdayUser.name}` : "Отсутствуют"}</span>
                </div>
                <div className={styles.main__birthdayBlockDate}>{birthdayUser.day || new Date().getDate()}.{birthdayUser.month || new Date().getMonth() + 1}</div>
            </div>
            <div className={styles.main__employeeStatusBlock} onClick={setDayState}>
                <div>Я заболел</div>
            </div>
        </main>
    );
};

export default Main;
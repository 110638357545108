import React, {useEffect, useRef, useState} from 'react';
import styles from "./Admin.module.css";

import {Link} from "react-router-dom";
import {deleteUser, getRoles, getUsers, registration, updateUser} from "../../http/userAPI";
import {useScrollbar} from "../../hooks";

const Admin = () => {
    const [users, setUsers] = useState(null | []);
    const [modalShow, setModalShow] = useState(false);
    const [roles, setRoles] = useState([]);
    const [userForm, setUserForm] = useState({
        id: null,
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        password: "",
        roleId: 1,
        dateOfBirth: ""
    });

    const tableRef = useRef();
    useScrollbar(tableRef, users)
    useEffect(()=>{
        getUsers()
            .then(res=>setUsers(res))
            .catch(console.error)
        getRoles()
            .then(res=>setRoles(res))
            .catch(console.error)
    },[]);

    const registrationUser = () =>{
        if (!userForm.firstName || !userForm.phone || !userForm.password || !new Date(userForm.dateOfBirth)) {
            alert("Неверно введены данные");
            return
        }
        if (userForm.id)
            updateUser(userForm)
                .then((res)=>{
                    console.log(res)
                    updateUserList()
                })
                .catch(err=>alert(`Произошла ошибка: ${err.message}`))
        else
            registration(userForm)
                .then(()=>{
                    updateUserList()
                })
                .catch(err=>alert(`Произошла ошибка: ${err.message}`))
    }

    const updateUserList = () => {
        getUsers()
            .then(res=>setUsers(res))
            .catch(console.error)
        setUserForm({
            firstName: "",
            lastName: "",
            middleName: "",
            phone: "",
            password: "",
            roleId: 1,
            dateOfBirth: ""
        });
        setModalShow(false);
    }
    
    const openModalInfo = (e, id) => {
        const user = users.filter(el=>el.id===id)[0]
        setUserForm({...user})
        setModalShow(true)
    }

    const deleteCurrentUser = () => {
        deleteUser(userForm.id)
            .then(()=>{
                updateUserList()
            })
            .catch(err=>alert(`Произошла ошибка: ${err.message}`))
    }

    return (
        <main className={styles.adminMain}>
            <div className={styles.main__navigationBlock}>
                <Link to={"/"}>Вернуться на главный экран</Link>
            </div>
            <div className={styles.main__tableTitle}>Список сотрудников</div>
            <table className={styles.main__tableBlock} ref={tableRef}>
                <thead>
                    <tr>
                        <th>Должность</th>
                        <th>Имя</th>
                        <th>Телефон</th>
                        <th>Дата рождения</th>
                    </tr>
                </thead>
                <tbody>
                    {users ?
                        users.map(el=>
                            <tr key={el.phone} onClick={e=>openModalInfo(e, el.id)}>
                                <td>{el.role.name}</td>
                                <td>{el.firstName}</td>
                                <td>{el.phone}</td>
                                <td>{new Date(el.dateOfBirth).getDate()}.{new Date(el.dateOfBirth).getMonth()+1}.{new Date(el.dateOfBirth).getFullYear()}</td>
                            </tr>
                        )
                        :null
                    }
                </tbody>
            </table>
            <button className={styles.main__button} onClick={()=>setModalShow(!modalShow)}>Добавить пользователя</button>

            <div className={styles.main__modal} style={{top: modalShow ? 0 : "-100vh"}}>
                <input placeholder={"Фамилия"} value={userForm.lastName} onChange={e=>setUserForm({...userForm, lastName: e.target.value})}/>
                <input placeholder={"Имя"} value={userForm.firstName} onChange={e=>setUserForm({...userForm, firstName: e.target.value})}/>
                <input placeholder={"Отчество"} value={userForm.middleName} onChange={e=>setUserForm({...userForm, middleName: e.target.value})}/>
                <input placeholder={"ДР YYYY-MM-DD"} value={userForm.dateOfBirth} onChange={e=>setUserForm({...userForm, dateOfBirth: e.target.value})}/>
                <input placeholder={"Номер телефона"} value={userForm.phone} onChange={e=>setUserForm({...userForm, phone: e.target.value})}/>
                <input placeholder={"Пароль"} value={userForm.password} onChange={e=>setUserForm({...userForm, password: e.target.value})}/>
                <select onChange={e=>setUserForm({...userForm, roleId: e.target.value})} value={userForm.roleId}>
                    {roles ? roles.map(el=><option key={el.id} value={el.id}>{el.name}</option>) : null}
                </select>
                <button onClick={registrationUser}>
                    Сохранить
                </button>
                {
                    userForm.id ?
                        <button onClick={deleteCurrentUser}>
                            Удалить
                        </button>
                        : null
                }
                <button onClick={()=>setModalShow(false)}>
                    Отмена
                </button>
            </div>
        </main>
    );
};

export default Admin;
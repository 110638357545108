import React, {useEffect, useRef, useState} from 'react';
import styles from "./Chart.module.css";
import {Link} from "react-router-dom";
import {useScrollbar} from "../../hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlay, faXmark} from "@fortawesome/free-solid-svg-icons"
import {getUsersWorkDay, getWeeksWorkDays} from "../../http/workAPI";
import {getWeekDay} from "../../utils/dateWorker";

const Chart = () => {
    const [employees, setEmployees] = useState(null | {});
    const [allWeekEmployees, setAllWeekEmployees] = useState(null | {});
    const [weekDays, setWeekDays] = useState(null | []);

    const table = useRef();
    useScrollbar(table, Object.keys(employees));
    const weekTable = useRef();
    useScrollbar(weekTable, allWeekEmployees);

    const calcWeekDays = () => {
        let nowDate = new Date();
        let weekFirstDay = new Date(nowDate - (nowDate.getDay() - 1) * 86400000);
        const week = [];
        for (let i = 0; i<7; i++){
            week.push({
                day: weekFirstDay.getDate(),
                dayOfWeek: getWeekDay(weekFirstDay.getDate(), weekFirstDay.getFullYear(), weekFirstDay.getMonth())
            })
            weekFirstDay.setDate(weekFirstDay.getDate()+1);
        }
        setWeekDays(week);
    }

    // const filterWeekEmployees = (employees) => {
    //     Object.keys(employees).map(el=>{
    //         const week = employees[el].worksDay;
    //         const filterWeek = [];
    //
    //         weekDays.map(daysEl=>{
    //             const day = week.filter(weekEl=>new Date(weekEl.date).getDate()===daysEl.day);
    //             if (day.length)
    //                 filterWeek.push(day[0])
    //             else filterWeek.push({stateId: 1})
    //         })
    //
    //         employees[el].worksDay = filterWeek;
    //     })
    //
    //     setAllWeekEmployees(employees);
    // }

    useEffect(()=>{
        if (!weekDays) return;

        const day = new Date();
        getWeeksWorkDays(day.getMonth()+1)
            .then(res=>{
                const employees = res;
                Object.keys(employees).map(el=>{
                    const week = employees[el].worksDay;
                    const filterWeek = [];
                    weekDays.map(daysEl=>{
                        const day = week.filter(weekEl=>{
                            return new Date(weekEl.date).getDate()===daysEl.day
                        });
                        if (day.length)
                            filterWeek.push(day[0])
                        else filterWeek.push({stateId: 1})
                    })

                    employees[el].worksDay = filterWeek;
                })

                setAllWeekEmployees(employees);
            })
    }, [weekDays])

    useEffect(() => {
        calcWeekDays();
        const day = new Date();
        const nextDay = new Date(day);
        nextDay.setDate(day.getDate()+1);
        getUsersWorkDay()
            .then(res=>setEmployees(res))
            .catch(err=>console.log(err))

    }, []);

    return (
        <main className={styles.chartMain}>
            <div className={styles.main__navigationBlock}>
                <Link to={"/"}>Вернуться на главный экран</Link>
            </div>
            <div className={styles.main__tableOfEmployees}>
                <div className={styles.main__tableOfEmployeesTitle}>Завтра работают:</div>
                <div className={styles.main__tableOfEmployeesList} ref={table}>
                    {
                        employees
                            ?
                            Object.keys(employees).map((title, index) => (
                                <details key={title} open={true}>
                                    <summary className={styles.main__tableOfEmployeesListSubTitle}>{index+1}. {title}</summary>
                                    <div>
                                        {employees[title].map(obj=>(
                                            <span key={obj.id} className={styles.main__tableOfEmployeesListName}>{obj.user.lastName.slice(0,1)+". "+obj.user.firstName}</span>
                                        ))}
                                    </div>
                                </details>
                            ))
                            : <></>
                    }
                </div>
            </div>
            <div className={styles.main__monthlyWorkTable} ref={weekTable}>
                <div className={styles.main__monthlyWorkTableWeekDays}>
                    {weekDays && weekDays.map(el=><div key={el.dayOfWeek}><span>{el.dayOfWeek}</span><span>{el.day}</span></div>)}
                </div>
                {allWeekEmployees ?
                    Object.keys(allWeekEmployees).map(el=>
                        <div key={el} className={styles.main__monthlyWorkTableRowEmployees}>
                            <div>
                                <span>{allWeekEmployees[el].position}</span>
                                <span>{el}</span>
                            </div>
                            <div>
                                {
                                    allWeekEmployees[el].worksDay.map((day, index)=>
                                        <div key={`${day.stateId}_${index}`}
                                            style={day.stateId === 3 ? {background: "#DF1010"} : {}}>{day.stateId === 1 ? "" : day.stateId === 2 ? (
                                            <FontAwesomeIcon icon={faCheck}/>) : (
                                            <FontAwesomeIcon icon={faXmark}/>)}</div>)
                                }
                            </div>
                        </div>
                    )
                    :
                    null
                }
            </div>
        </main>
    );
};

export default Chart;
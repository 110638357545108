import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";

export const getUserWorkMonth = async (month, year) => {
    const user = jwt_decode(localStorage.getItem('token'));
    const {data} = await $host.get(`api/work?month=${month}&year=${year}&userId=${user.id}`);
    return data;
}

export const setWorkState = async (stateId, date) => {
    const {data} = await $authHost.post(`api/work/`, {stateId, date})
    return data;
}

export const getNextWorkDay = async () => {
    let date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    const {data} = await $authHost.get(`api/work/next?date=${date.getTime()}`);
    return data;
}

export const getWeeksWorkDays = async (month) => {
    const {data} = await $host.get(`api/work?month=${month}`);
    return data;
}

export const getUsersWorkDay = async () => {
    let date = new Date();
    date.setDate(date.getDate()+1);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate())
    const {data} = await $authHost.get(`api/work?date=${date.getTime()}`);
    return data;
}
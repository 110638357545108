import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";

export const registration = async (userForm) => {
    const {data} = await $authHost.post('api/user/registration', {...userForm});
    return jwt_decode(data.token);
}

export const login = async (phone, password, save) => {
    const {data} = await $host.post('api/user/login', {phone, password});
    if (save)
        localStorage.setItem('token', data.token);
    return jwt_decode(data.token);
}

export const check = async () => {
    const {data} = await $authHost.get('api/user/auth');
    localStorage.setItem('token', data.token);
    return jwt_decode(data.token);
}

export const getBirthday = async () => {
    const {data} = await $host.get('api/user/birthday');
    return data;
}

export const getUsers = async () => {
    const {data} = await $authHost.get('api/user');
    return data;
}

export const getRoles = async ()=> {
    const {data} = await $authHost.get('api/role');
    return data;
}

export const deleteUser = async (id) => {
    const {data} = await $authHost.delete(`api/user?id=${id}`);
    return data;
}

export const updateUser = async (userForm) => {
    const {data} = await $authHost.put(`api/user`, {...userForm});
    return data;
}

export const gotSick = async () => {
    const {data} = await $authHost.get(`api/telegram/b2617170f155a5f1d6c29853fb2a41c4`);
    return data;
}
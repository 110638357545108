import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes,} from "react-router-dom";
import {authRoutes, publicRoutes} from "./routes";
import {ADMIN_ROUTE, CHART_ROUTE, LOGIN_ROUTE, MAIN_ROUTE} from "./utils/consts";
import Login from "./pages/Login/Login";
import Main from "./pages/Main/Main";
import Chart from "./pages/Chart/Chart";
import {check} from "./http/userAPI";
import Admin from "./pages/Admin/Admin";

function App() {
    const [user, setUser] = useState(null);
    async function checkAuth(){
        try {
            const data  = await check();
            console.log(data);
            if (data) setUser(data);
        }
        catch (e) {

        }
    }

    useEffect(()=>{
        checkAuth();
    },[]);

    return (
        <BrowserRouter className="App">
            <Routes>
                {
                    user ?
                        <>
                            <Route path={MAIN_ROUTE} element={<Main user={user} setUser={setUser}/>} exact/>
                            <Route path={CHART_ROUTE} element={<Chart/>} exact/>
                            {user.roleId===1 && <Route path={ADMIN_ROUTE} element={<Admin/>} exact/>}
                        </>
                        :
                        <Route path={LOGIN_ROUTE} element={<Login setUser={setUser}/>} exact/>
                }
                <Route
                    path="*"
                    element={<Navigate to="/" replace/>}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;

import React, {useState} from 'react';
import styles from "./Login.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faXmarkCircle} from "@fortawesome/free-regular-svg-icons";
import {login} from "../../http/userAPI";

const Login = ({setUser}) => {
    const [visiblyPassword, setVisiblyPassword] = useState(false);
    const [loginInfo, setLoginInfo] = useState({phone: "", password: ""})
    const [localStorageSave, setLocalStorageSave] = useState(false);

    const clickLoginButton = async ()=>{
        try {
            let data = await login(loginInfo.phone, loginInfo.password, true);
            setUser(data);
        }
        catch (e) {
            alert(e.response.data.message)
        }
    }

    return (
        <main>
            <div className={styles.main__form}>
                <h1 className={styles.main__header}>Вход</h1>
                <span className={styles.main__inputBlock}>
                    <span className={styles.main__inputPlaceholder}>Телефон</span>
                    <input className={styles.main__inputPhone} type={"text"} value={loginInfo.phone}
                           onChange={e => setLoginInfo({...loginInfo, phone: e.target.value})}/>
                </span>
                <span className={styles.main__inputBlock}>
                    <FontAwesomeIcon icon={visiblyPassword ? faEyeSlash : faEye}
                                     className={styles.main__inputPasswordVisible} size={"xl"}
                                     onClick={() => setVisiblyPassword(!visiblyPassword)}/>
                    <span className={styles.main__inputPlaceholder}>Пароль</span>
                    <input className={styles.main__inputPassword} type={visiblyPassword ? "text" : "password"}
                           value={loginInfo.password}
                           onChange={e => setLoginInfo({...loginInfo, password: e.target.value})}/>
                    <FontAwesomeIcon className={styles.main__inputPasswordXMark} icon={faXmarkCircle}
                                     onClick={() => setLoginInfo({...loginInfo, password: ""})} size={"xl"}/>
                </span>
                <button className={styles.main__button} onClick={clickLoginButton}>Войти</button>
                <span onClick={()=>setLocalStorageSave(!localStorageSave)}>
                    <input className={styles.main__inputCheckBox} name={"checkbox-save"} type={"checkbox"} checked={localStorageSave} onChange={e=>setLocalStorageSave(e.target.checked)}/>
                    <label htmlFor={"checkbox-save"}>запомнить меня на этом устройстве</label>
                </span>
            </div>
        </main>
    );
};

export default Login;